import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import SlickSlider from "../../../components/shared/slick";
import {
  SectionBg,
  TestimonialSection,
  TestimonialWrap,
  TestimonialCard,
  ContentWrap,
  Thumbnail,
  Quote,
  BigIcon,
} from "./testimonial.stc";

import quoteIcon from '@iconify/icons-et/quote';

const TestimonialSlider = ({ options }) => {
  const homeTestimonialData = useStaticQuery(graphql`
    query homeTestimonialQuery {
      allSanityTestimonial(limit: 10) {
        edges {
          node {
            _id
            name
            position
            company
            testimonial
            img {
              asset {
                fluid(maxHeight: 250, maxWidth: 250) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
        }
      }
    }
  `);

  const testimonials = homeTestimonialData.allSanityTestimonial.edges;
  return (
    <SectionBg>
      <TestimonialSection>
        <TestimonialWrap>
          <SlickSlider slideOptions={options}>
            {testimonials.map((data) => (
              <TestimonialCard className="item" key={data.node._id}>
                <ContentWrap>
                  <Thumbnail fluid={data.node.img.asset.fluid} alt="" />
                  <Quote>
                    <p>{data.node.testimonial}</p>
                    <footer>
                      <cite>
                        <span>{data.node.name}</span>
                        {data.node.position}, {data.node.company}
                      </cite>
                    </footer>
                  </Quote>
                  <BigIcon width="40px" color="#449ac7" icon={quoteIcon} />
                </ContentWrap>
              </TestimonialCard>
            ))}
          </SlickSlider>
        </TestimonialWrap>
      </TestimonialSection>
    </SectionBg>
  );
};

TestimonialSlider.propTypes = {
  section: PropTypes.object,
};

TestimonialSlider.defaultProps = {
  options: {
    slidesToShow: 3,
    dots: false,
    speed: 500,
    arrows: false,
    autoplay: true,
    infinite: true,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 678,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  },
};

export default TestimonialSlider;
