import styled from "styled-components";
import { device } from "../../../theme";
import Image from "gatsby-image";
import { Icon } from '@iconify/react';

export const SectionBg = styled.div`
  background: #f8f8f8;
`;

export const TestimonialSection = styled.section`
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
  width: 1170px;

  @media ${device.large} {
    width: 100%;
  }
`;

export const TestimonialWrap = styled.main`
  padding: 100px 0;
  overflow: visible !important;
`;

export const TestimonialCard = styled.article`
  width: (100%/3);
  padding: 0 20px;
  position: relative;
  min-height: 1px;
  margin: 50px auto;
`;

export const ContentWrap = styled.div`
  padding: 40px 50px 50px 50px;
  border-top: 1px solid #f8f8f8;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
  background: #fff;
  text-align: center;
  transition: all 0.3s;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 22px 20px rgba(0, 0, 0, 0.15);
  }
`;
export const Thumbnail = styled(Image)`
  width: 100px;
  height: auto;
  margin: 0 auto;
  margin-bottom: 50px;
`;

export const Quote = styled.blockquote`
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.2px;
  line-height: 1.8em;
  color: #999;
  font-style: normal;

  p {
    margin: 0;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0.2px;
    line-height: 1.8em;

    &:before {
      content: '';
      display: block;
      position: relative;
      width: 70%;
      margin: 0 auto 30px auto;
      border-top: 1px solid #ccc;
    }

    &:after {
      content: '';
      display: block;
      position: relative;
      width: 70%;
      margin: 30px auto 0 auto;
      border-top: 1px solid #ccc;
    }
  }

  footer {
    padding-top: 25px;
    display: block;
    font-size: 80%;
    line-height: 1.42857143;
    color: #777;

    cite {
      text-transform: uppercase;
      font-size: 1.07em;
      letter-spacing: 3px;
      color: #777;
      font-style: normal;
      line-height: 1.8;

      span {
        display: block;
        letter-spacing: 2px;
        color: #111;
      }
    }
  }
`;

export const BigIcon = styled(Icon)`
  width: ${props => props.width || "50px"};
  color: ${props => props.color || "#777"};
  height: auto;
`;